import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { memo, useCallback, useMemo } from "react";
import { useState } from "react";
import { followEntityPromise, unfollowEntityPromise } from "routines/follow";

import EllipsisMenuBackItem from "components/Buttons/EllipsisMenuButton/EllipsisMenuBackItem";
import EllipsisMenuButton from "components/Buttons/EllipsisMenuButton/EllipsisMenuButton";
import EllipsisMenuItem from "components/Buttons/EllipsisMenuButton/EllipsisMenuItem";
import { getModeratorModeCookie } from "components/UseIsModerator/UseIsModeratorProvider";
import UserListSharingLinks from "components/UserLists/UserListSharingLinks";

import modalActions from "actions/modals";
import { getBaseUrl } from "constants/base";
import sendGAEvent from "utils/sendGAEvent";

import useActionCreators from "hooks/useActionCreators";
import useRoutinePromises from "hooks/useRoutinePromises";
import { useStyles } from "hooks/useStyles";
import useUserHasInternalPermission from "hooks/useUserHasInternalPermission";
import useUserHasPro from "hooks/useUserHasPro";
import useWindowSize from "hooks/useWindowSize";

const baseStyles = {
  column: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
};

const ELLIPSIS_STEPS = ["nova", "share"];

const UserlistEllipsisMenu = (props) => {
  const { list, closeOnOutsideclick, isOwner, toggleLike } = props;

  const { styles } = useStyles(baseStyles, props);
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const hasPro = useUserHasPro();

  const userIsInternal = useUserHasInternalPermission();
  const isModerating = getModeratorModeCookie();
  const { isWindowSizeOrLess } = useWindowSize();
  const mobile = isWindowSizeOrLess("medium");

  const currentUserFollows = list?.getIn(["user_data", "follows"]);

  const analyticsVariables = useMemo(
    () => ({
      ellipsisMenuType: "UserlistEllipsisMenu",
      list_id: list && list.get("id"),
      list_title: list && list.get("title"),
    }),
    [list]
  );

  const listPrivacy = list?.get("privacy");
  const entityType = list?.get("entity_type");
  const userHasLiked = list && list?.getIn(["user_data", "is_liked"]);
  const linkUrlId =
    listPrivacy === "link_only" ? list?.get("link_only_id") : list?.get("id");

  const { followEntity, unfollowEntity } = useRoutinePromises({
    followEntity: followEntityPromise,
    unfollowEntity: unfollowEntityPromise,
  });

  const { showModal } = useActionCreators({
    showModal: modalActions.showModal,
  });

  const preventDefault = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleShareClick = useCallback(
    (onChangeStep) => (e) => {
      sendGAEvent({
        listId: list?.get("id"),
        list_name: list?.get("title"),
        entity_type: "list",
        context: "ellipsis menu",
        componentContext: "UserlistEllipsisMenu",
        action: "shareModalOpen",
      });
      preventDefault(e);
      setSubMenuOpen(!subMenuOpen);
      onChangeStep("share")(e);
    },
    [subMenuOpen, list]
  );

  const exportList = useCallback(
    (closeEllipsisPopover) => {
      if (entityType === "episode") {
        showModal("listDownloadModal", {
          link: `${getBaseUrl()}/export/list/${linkUrlId}/rss`,
          buttonLabel: "RSS",
          downLoadTitle: "Your RSS file is ready.",
          downloadKey: "rss",
        });
      } else {
        if (hasPro) {
          showModal("downloadContacts", {
            list_id: list?.get("id"),
            context: "userlistHeaderExportButton",
            stage: "format_selection",
          });
        } else {
          showModal("publicDownloadContacts", {
            list_id: list?.get("id"),
            context: "userlistHeaderExportButton",
            stage: "format_selection",
          });
        }
      }
      closeEllipsisPopover && closeEllipsisPopover();
      sendGAEvent({
        ...analyticsVariables,
        action: "exportContactsButtonClick",
        context: "UserListPage",
        entityType,
      });
    },
    [analyticsVariables, entityType, hasPro, linkUrlId, list, showModal]
  );

  const createAList = useCallback(
    (closeEllipsisPopover) => {
      sendGAEvent({
        ...analyticsVariables,
        action: "createListButtonClick",
        menuItem: "Share",
        object: "Header",
        context: "User List Page",
      });

      showModal("createList");
      closeEllipsisPopover && closeEllipsisPopover();
    },
    [analyticsVariables, showModal]
  );

  const handleFollow = useCallback(
    (e) => {
      e.preventDefault();
      if (currentUserFollows) {
        unfollowEntity({
          entity_type: "userlist",
          entity_id: list.get("id"),
          entity: list,
        });
      } else {
        followEntity({
          entity_type: "userlist",
          entity_id: list.get("id"),
          entity: list,
        });
      }
    },
    [currentUserFollows, followEntity, list, unfollowEntity]
  );

  const renderContent = useCallback(
    (contentProps) => {
      const { currentStep, closeEllipsisPopover, onBack, onChangeStep } =
        contentProps;

      switch (currentStep) {
        case "share":
          return (
            <div key="share" className={css(styles.column)}>
              <EllipsisMenuBackItem {...contentProps} onBack={onBack} />
              <UserListSharingLinks listId={list?.get("id")} />
            </div>
          );
        default:
          return (
            <div key="default" className={css(styles.column)}>
              <EllipsisMenuItem
                key="createAList"
                entity={list}
                entity_type="userlist"
                label="Create a list"
                onClick={() => createAList(closeEllipsisPopover)}
                onMouseDown={preventDefault}
                onMouseUp={preventDefault}
              />
              <EllipsisMenuItem
                key="likeList"
                entity={list}
                entity_type="userlist"
                label={userHasLiked ? "Remove like" : "Like this list"}
                onClick={toggleLike}
                onMouseDown={preventDefault}
                onMouseUp={preventDefault}
              />
              {mobile && (
                <EllipsisMenuItem
                  key="exportList"
                  entity={list}
                  entity_type="userlist"
                  label="Export List"
                  onClick={() => exportList(closeEllipsisPopover)}
                  onMouseDown={preventDefault}
                  onMouseUp={preventDefault}
                />
              )}
              {userIsInternal && isModerating && (
                <EllipsisMenuItem
                  key="open-list-in-nova"
                  entity={list}
                  entity_type="nova"
                  label="Open in Nova"
                  to={`https://api.podchaser.com/nova/resources/list-resources/${list?.get(
                    "id"
                  )}`}
                  link
                  target="_blank"
                  anchor
                  onMouseDown={preventDefault}
                  onMouseUp={preventDefault}
                />
              )}
              {!isOwner && (
                <EllipsisMenuItem
                  key="follow"
                  entity={list}
                  entity_type="userlist"
                  label={currentUserFollows ? "Following" : "Follow"}
                  onClick={handleFollow}
                  onMouseDown={preventDefault}
                  onMouseUp={preventDefault}
                />
              )}
              <EllipsisMenuItem
                key="share"
                entity={list}
                entity_type="userlist"
                label="Share"
                onClick={handleShareClick(onChangeStep)}
                onMouseDown={preventDefault}
                onMouseUp={preventDefault}
              />
            </div>
          );
      }
    },
    [
      styles.column,
      list,
      userHasLiked,
      toggleLike,
      mobile,
      userIsInternal,
      isModerating,
      isOwner,
      currentUserFollows,
      handleFollow,
      handleShareClick,
      createAList,
      exportList,
    ]
  );

  return (
    <EllipsisMenuButton
      renderContent={renderContent}
      steps={ELLIPSIS_STEPS}
      analyticsVariables={analyticsVariables}
      closeOnOutsideclick={closeOnOutsideclick}
      subMenuOpen={subMenuOpen}
      setSubMenuOpen={setSubMenuOpen}
      passedEllipsisButtonStyles={props?.styles}
      styles={props?.styles}
    />
  );
};

UserlistEllipsisMenu.propTypes = {
  list: PropTypes.instanceOf(Map).isRequired,
  showEllipsisItems: PropTypes.array,
};

UserlistEllipsisMenu.defaultProps = {
  showEllipsisItems: null,
};

export default memo(UserlistEllipsisMenu);
